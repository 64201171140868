import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiRouteUtil} from "../../shared/utils/api-route.util";

@Injectable({
  providedIn: 'root',
})
export class SystemAccessNotificationService {
  constructor(private http: HttpClient) {}

  getSystemAccessNotification(): Observable<string> {
    return this.http.get(ApiRouteUtil.privacyNote(), {
      responseType: 'text',
    });
  }
}
