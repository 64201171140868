import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LayoutComponent} from './components/layout/layout.component';
import {LoadingService} from './core/services/loading.service';
import {delay} from 'rxjs';
import {LoaderComponent} from './components/loader/loader.component';
import {ErrorHandlerService} from './shared/services/error-handler.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, LayoutComponent, LoaderComponent],
  providers: [ErrorHandlerService],
})
export class AppComponent {
  loading: boolean = false;

  constructor(private _loading: LoadingService) {}

  ngOnInit(): void {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  ngOnDestroy(): void {
    this._loading.loadingSub.unsubscribe();
  }
}
