<ng-content></ng-content>
@if (isFooter) {
  <footer>
    <div>
      <a
        class="terms-policy-link"
        aria-label="Terms of use"
        target="_blank"
        [href]="policyDomain + 'terms'"
        rel="noopener noreferrer"
      >
        {{ LABEL_CONSTANT.TERMS_OF_USE }}
      </a>
    </div>
    <div>
      <a
        class="terms-policy-link"
        aria-label="Privacy Policy"
        target="_blank"
        [href]="policyDomain + 'privacy'"
        rel="noopener noreferrer"
      >
        {{ LABEL_CONSTANT.PRIVACY_POLICY }}
      </a>
    </div>
  </footer>
}
