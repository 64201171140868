import {ErrorHandler, Injectable, isDevMode} from '@angular/core';
import {GENERAL_ERROR_CONSTANT} from '../../constant/constant';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    // Log the error to the console.
    isDevMode() && console.error(error, GENERAL_ERROR_CONSTANT.UNEXPECTED_ERROR);
  }
}
