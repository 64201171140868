import {NgClass, NgOptimizedImage} from '@angular/common';
import {Component, Input, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl, ReactiveFormsModule} from '@angular/forms';
import {FormControlPipe} from '../../shared/pipes/formControl.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import {ControlMessagesComponent} from '../control-messages/control-messages.component';

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {},
};

@Component({
  selector: 'app-custom-input',
  standalone: true,
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
  imports: [
    NgClass,
    ReactiveFormsModule,
    FormControlPipe,
    FontAwesomeModule,
    ControlMessagesComponent,
    NgOptimizedImage,
  ],
})
export class CustomInputComponent {
  value: string | undefined;
  @Input() public required: boolean = false;
  @Input() public label?: string;
  @Input() public id?: string;
  @Input() public type: string = 'text';
  @Input() public icon?: string;
  @Input() public showHide?: boolean;
  @Input() public placeholder: string ='';

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      // And we use NOOP_VALUE_ACCESSOR so WrappedInput don't do anything with NgControl
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }

  public showHideText() {
    if (this.type === 'text') {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }
}
