import {HttpHandlerFn, HttpInterceptorFn} from '@angular/common/http';
import {catchError, throwError} from 'rxjs';
import {inject} from '@angular/core';

import {HTTP_ERROR_CONSTANT} from '../../constant/constant';
import {HttpErrorService} from '../services/http-error.service';

export const errorInterceptor: HttpInterceptorFn = (req, next: HttpHandlerFn) => {
  const errorService = inject(HttpErrorService);
  return next(req).pipe(
    catchError((error) => {
      if (error.status === 0) {
        errorService.httpError.set(HTTP_ERROR_CONSTANT.GENERAL_HTTP_ERROR);
      } else if ([401, 403].includes(error.status)) {
        errorService.httpError.set(HTTP_ERROR_CONSTANT.UNAUTHORIZED_HTTP_ERROR);
      } else if (error.message) {
        errorService.httpError.set(error.message);
      } else {
        errorService.httpError.set(HTTP_ERROR_CONSTANT.GENERAL_HTTP_ERROR);
      }
      return throwError(() => error);
    }),
  );
};
