<app-card [systemAccessNotification]="markdown" [subHeading]="flowLabel(getSignInState())">
  @switch (getSignInState()) {
    @case (SIGN_IN_STATE.SIGN_IN) {
      <app-sign-in
        [userName]="userName"
        [clientId]="clientId"
        (setSignInState)="setSignInState($event)"
      />
    }
    @case (SIGN_IN_STATE.FORGOT_PASSWORD) {
      <app-forgot-password
        [userName]="userName"
        (setSignInState)="setSignInState($event)"
        (setResetFlowUsername)="setResetFlowUsername($event)"
      />
    }
    @case (SIGN_IN_STATE.CONFIRM_SIGN_IN) {
      <app-reset-password
        [resetWithCode]="false"
        [clientId]="clientId"
        (setSignInState)="setSignInState($event)"
      />
    }
    @case (SIGN_IN_STATE.RESET_PASSWORD) {
      <app-reset-password
        [resetWithCode]="true"
        [userName]="getResetFlowUsername()"
        (setSignInState)="setSignInState($event)"
      />
    }
  }
</app-card>
