<form class="form-container" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div>
    <app-custom-input
      [formControlName]="'userName'"
      [type]="'text'"
      [id]="'username'"
      [label]="LABEL_CONSTANT.USERNAME_EMAIL"
      [icon]="'user-input-icon.svg'"
      [placeholder]="PLACEHOLDER_CONSTANT.EMAIL_FORMAT"
      [required]="true"
    />
  </div>
  <app-button
    [type]="'submit'"
    [className]="'primary-form-button'"
    [label]="LABEL_CONSTANT.SEND_CODE"
  />
  <span class="nav-link" (click)="switchToLoginFlow()"> {{ LABEL_CONSTANT.LOGIN }} </span>
</form>
